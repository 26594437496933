import React from 'react';

import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { TextButton, TextButtonPriority, ThreeDotsLoader } from 'wix-ui-tpa';

import { getCertificate } from '@wix/ambassador-challenges-v1-participant/http';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';

import { useGeneralData } from '../../contexts/GeneralDataProvider/GeneralDataContext';
import { useHttpClient } from '../../hooks/useHttpClient';
import { useToast } from '../../contexts/ToastContext/ToastContext';
import { ToastType } from '../../contexts/ToastContext/interfaces';

import { classes } from './ViewCertificate.st.css';
import { useChallengeData } from '../../contexts/ChallengeDataProvider/ChallengeDataContext';
import { ButtonNames } from '../../contexts/main/biInterfaces';

export interface IViewCertificateProps {
  certificateId: string;
  programId?: string;
}

export const ViewCertificate: React.FC<IViewCertificateProps> = ({
  certificateId,
  programId,
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { showToast } = useToast();
  const { t } = useTranslation();
  const { instance } = useGeneralData();
  const { challengeData } = useChallengeData();
  const httpClient = useHttpClient(instance);
  const bi = useBi();

  const onClick = React.useCallback(async () => {
    if (!certificateId) {
      return;
    }

    bi.report(
      memberWebAppButtonClickV2({
        buttonName: ButtonNames.GetCertificate,
      }),
    );

    setLoading(true);

    try {
      const {
        data: { certificateUrl },
      } = await httpClient.request(
        getCertificate({
          participantId: certificateId,
          challengeId: challengeData?.challenge?.id || programId,
        }),
      );

      if (!certificateUrl) {
        throw new Error('no certificate found');
      }

      window.open(certificateUrl, '_blank');
    } catch (error) {
      showToast(t('certificates.toast.error'), {
        type: ToastType.error,
      });
      console.log('Error getting certificate: ', error);
    }

    setLoading(false);
  }, [httpClient, certificateId]);

  return (
    <TextButton
      className={classes.text}
      onClick={onClick}
      priority={TextButtonPriority.link}
    >
      {loading ? (
        <ThreeDotsLoader />
      ) : (
        t('certificates.button.view-certificate')
      )}
    </TextButton>
  );
};
